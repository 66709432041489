import Rails from 'rails-ujs'
import $ from 'jquery'
import Turbolinks from 'turbolinks'
import Foundation from 'foundation-sites'
import * as ActiveStorage from 'activestorage'
import 'slick-carousel/slick/slick'
import 'slick-carousel/slick/slick.css'

// Styles
import 'application.scss'
import 'tailwindcss/tailwind.css'

// Reactivity
import 'alpinejs'

// JavaScript
import 'controllers'

// Images
require.context('../images', true)

Rails.start()
Turbolinks.start()
ActiveStorage.start()
Foundation.addToJquery($)

$(document).on('turbolinks:load', function () {
  $(document).foundation()

  // Nav Toggle
  $("[data-toggle='nav']").on('click', function () {
    $("[data-module='nav']").toggleClass('nav--active')
  })

  // Scrolling [Sticky Header, Dash, Projects]
  $(window).on('scroll', function () {
    const $header = $("[data-module='header']")
    $(window).scrollTop() > 0 ? $header.addClass('header--active') : $header.removeClass('header--active')
  })

  // Process
  $('.process__block').on('mouseover', function () {
    const image = $(this).attr('data-image')
    $('#processWrapper').css('backgroundImage', `url(${image})`)
  })

  $('.process__block').on('mouseleave', function () {
    $('#processWrapper').css('backgroundImage', '')
  })

  // Carousel

  $('#heroProducts').slick({
    loop: true,
    arrows: true,
    dots: false,
  })

  $("[data-slick='service-showcase']").slick({
    loop: true,
    arrows: true,
    dots: true,
    customPaging: function (slider, i) {
      const slide = slider.$slides[i]
      const title = $(slide).find('.service-showcase__item').attr('title') || 'AMOBOX'
      return `<button>${title}</button>`
    },
  })

  $("[data-slick='testimonials']").slick({
    loop: true,
    arrows: false,
    dots: false,
    autoplay: true,
    autplaySpeed: 5000,
  })

  $('#project-hero-background').slick({
    arrows: false,
    dots: false,
    asNavFor: '#project-hero-navigation, #project-hero-item',
  })

  $('#project-hero-item').slick({
    arrows: false,
    dots: false,
    asNavFor: '#project-hero-background, #project-hero-navigation',
  })

  $('#project-hero-navigation').slick({
    arrows: true,
    dots: false,
    asNavFor: '#project-hero-background, #project-hero-item',
  })
})
