import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation, EffectFade } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, EffectFade])

export default class extends Controller {
  static targets = ['next', 'prev', 'container']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 16,
      autoplay: {
        delay: 8000,
      },
      autoHeight: true,
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      }
    })
  }
}
