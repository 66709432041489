import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation])

export default class extends Controller {
  static targets = ['next', 'prev', 'container']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 32,
      autoplay: {
        delay: 10000,
      },
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      breakpoints: {
        648: {
          slidesPerView: 2,
        }
      }
    })
  }
}
